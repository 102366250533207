p {
    margin: 0;
}

.contacto {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacto-container {
    color: #10182E;
    text-align: center;
}

.contacto-container-titulo-texto {
    font-size: 66px;
    font-weight: 600;
}

.contacto-container-info-titulo-texto {
    font-size: 30px;
}

.contacto-container-info-boton-contenedor {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
}

.contacto-container-info-boton {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    cursor: pointer;
    color: rgb(25, 78, 225);
}

@media screen and (max-width: 860px) {
    .contacto-container-titulo-texto {
        font-size: 56px;
    }
    
    .contacto-container-info-titulo-texto {
        font-size: 24px;
    }
}

@media screen and (max-width: 680px) {
    .contacto-container-titulo-texto {
        font-size: 30px;
        font-weight: 600;
    }
    
    .contacto-container-info-titulo-texto {
        font-size: 14px;
    }

    .contacto-container-info-boton-contenedor {
        display: flex;
        flex-direction: row;
        gap: 1px;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .contacto-container-info-boton {
        font-size: 40px;
    }
}