.check-icon {
    color: #194EE1;
    font-size: 34px;
    margin-left: -18.3px;
    background-color: white;
    position: absolute;
    z-index: 1;
}

.timeline-content-principal {
    border-left: 2px solid black !important;
    width: 90%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 105vmax !important;
}

.timeline-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.timeline-content-text {
    margin-left: 30px;
    border: 1px solid #dadee4;
    border-radius: 10px;
    width: 600px;
}

.timeline-top-content-text {
    padding: 10px;
    border-bottom: 1px solid #dadee4;
}

.timeline-bottom-content-text {
    padding: 10px;
}