.acercaDeMi {
    background-color: #10182e;
    color: white;
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    padding: 0 !important;
}

.acerca-presentacion-info {
    position: absolute;
    top: 25%;
    padding-left: 3%;
}

.acerca-presentacion-info-textos-contenedor {
    margin-bottom: 30px;
}

.acerca-presentacion-info-texto-menor {
    font-size: 66px;
}

.acerca-presentacion-info-texto-mayor {
    font-size: 80px;
    font-weight: bold;
}

.acerca-presentacion-info-button {
    background-color: #194EE1;
    border: none;
    box-shadow: none;
    color: white;
    font-size: 26px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 10px;
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (max-width: 620px) {
    .acerca-presentacion-info {
        position: absolute;
        top: 25%;
    }    

    .acerca-presentacion-info-texto-menor {
        font-size: 46px;
        text-align: center;
    }
    
    .acerca-presentacion-info-texto-mayor {
        font-size: 60px;
        text-align: center;
        font-weight: bold;
    }

    .acerca-presentacion-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}



@media screen and (max-width: 440px) {
    .acerca-presentacion-info-texto-menor {
        font-size: 36px;
        text-align: center;
    }
    
    .acerca-presentacion-info-texto-mayor {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
    }
}