p {
    margin: 0;
}

.App {
    width: 100vw;
    height: 100vh;
    color: white;
    position: relative;
}

.custom-toggle-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    padding: 3px;
    background-color: transparent;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 4px;
    cursor: pointer;
}

.acerca-menu-flotante {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 40px;
    top: 4%;
    right: 4%;
    z-index: 2;
}

.acerca-menu-flotante-botones-navegacion {
    background-color: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (max-width: 620px) {
    .acerca-menu-flotante {
        width: 90vw;
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 40px;
        top: 4%;
        z-index: 2;
    }
}

@media screen and (max-width: 475px) {
    .custom-toggle-switch {
        font-size: 10px;
    }

    .acerca-menu-flotante-botones-navegacion {
        font-size: 10px;
    }

}