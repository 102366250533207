.acerca-de-mi-extra {
    width: 100vw;
    height: 100vh;
    color: #10182E;
}

.acerca-de-mi-extra-top-contenedor {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.acerca-de-mi-extra-top-boton-contenedor {
    color: #194EE1;
    font-size: 50px;
    cursor: pointer;
}

.acerca-de-mi-extra-top-texto {
    font-size: 22px;
    font-weight: 500;
}

.acerca-de-mi-extra-bottom-contenedor {
    display: flex;
    flex-direction: row;
}

.acerca-de-mi-extra-subbottom-izq {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    height: 82vh;
    width: 50%;
}

.acerca-de-mi-extra-subbottom-der {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acerca-de-mi-foto-presentacion {
    width: 40%;
    border-radius: 50%;
    box-shadow: 0px 4px 1px 2px rgba(174, 174, 174, 0.17);
}

.acerca-de-mi-extra-subbottom-foto-contenedor {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}
.acerca-de-mi-extra-subbottom-iconos-contenedor {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    padding-top: 3%;
    gap: 20px;
}

.acerca-de-mi-extra-iconos {
    width: 40px;
    height: 40px;
    color: #10182E;
    transition: transform 0.3s;
}

@keyframes jump {
    50% {
        transform: translateY(-20px); 
    }
    100% {
        transform: translateY(0);
    }
}

.acerca-de-mi-extra-iconos:hover {
    animation: jump 0.5s ease;
    cursor: pointer;
}
.left-pf-icon {
    height: 42px;
    width: 42px;
}

@media screen and (max-width: 1400px) {
    /*.acerca-de-mi-extra-bottom-contenedor {
        flex-direction: column;
    }

    .acerca-de-mi-extra-subbottom-izq {
        display: flex;
        justify-content: center;
        overflow-y: scroll;
        height: 50%;
    }
    
    .acerca-de-mi-extra-subbottom-der {
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }*/

    .acerca-de-mi-foto-presentacion {
        width: auto;
    }
}

@media screen and (max-width: 780px) {
    .acerca-de-mi-extra-bottom-contenedor {
        flex-direction: column;
        width: 100%;;
    }

    .acerca-de-mi-extra-subbottom-izq {
        display: flex;
        justify-content: center;
        overflow-y: scroll;
        height: 50%;
        width: 100%;
    }
    
    .acerca-de-mi-extra-subbottom-der {
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .acerca-de-mi-foto-presentacion {
        width: 40%;
    }
}