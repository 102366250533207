p {
    margin: 0;
}

.conocimientos {
    background-color: white;
    width: 100vw;
    height: 100vh;
}

.conocimientos-container-organizador {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
}

.conocimientos-container-organizador-dos {
    margin-top: 10%;
    position: absolute;
    width: 95%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 500px) {
    .conocimientos {
        padding-top: 5%;
    }
}