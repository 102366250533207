.contenedor-conocimiento {
    background-color: transparent;
    color: black;
    box-shadow: 0.3px 1px 2px 0px rgba(13, 13, 13, 0.25);
    border: 1px solid rgb(222, 222, 222);
    border-radius: 10px;
}

.contenedor-conocimiento-top {
    display: flex;
    height: 90px;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgb(222, 222, 222);
}

.contenedor-conocimiento-logo {
    width: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedor-conocimiento-titulo {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 1px solid rgb(222, 222, 222);
    border-right: 1px solid rgb(222, 222, 222);
    width: 85%;
}

.contenedor-conocimiento-titulo-texto {
    margin-left: 2%;
}

.contenedor-conocimiento-boton-logo-ocultar-conocimiento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.contenedor-conocimiento-texto {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedor-conocimiento-texto-subcontenedor {
    padding: 15px;
}

@media screen and (max-width: 650px) {
    .imgicon {
        width: 60%;
    }

    .contenedor-conocimiento {
        font-size: 14px;
    }

    .contenedor-conocimiento-top {
        display: flex;
        height: auto;
    }
}